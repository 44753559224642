import React from 'react';
import clsx from 'clsx';
import clientConfig from '../../../client-config';
import BaseBlockContent from '@sanity/block-content-to-react';
import { Figure } from './figure';
import { CustomBlockContent } from './custom-block-content';
import { Slideshow } from './slideshow';
import { Player } from './player';
import { List, ListItem } from './list';
import quoteStyles from './block-quote.module.scss';
import headingStyles from './heading.module.scss';

const serializers = {
  types: {
    block({ node: { style, first }, children }) {
      switch (style) {
        case 'h1':
          return <h1 className={clsx(headingStyles.h1, first && headingStyles.firstHeading)}>{children}</h1>;

        case 'h2':
          return <h2 className={clsx(headingStyles.h2, first && headingStyles.firstHeading)}>{children}</h2>;

        case 'h3':
          return <h3 className={clsx(headingStyles.h3, first && headingStyles.firstHeading)}>{children}</h3>;

        case 'h4':
          return <h4 className={clsx(headingStyles.h4, first && headingStyles.firstHeading)}>{children}</h4>;

        case 'h5':
          return <h5 className={clsx(headingStyles.h5, first && headingStyles.firstHeading)}>{children}</h5>;

        case 'blockquote':
          return <blockquote className={quoteStyles.quote}>{children}</blockquote>;

        default:
          return <p>{children}</p>;
      }
    },
    figure(props) {
      return <Figure {...props.node} />;
    },
    slideshow(props) {
      return <Slideshow {...props.node} />;
    },
    youtube: ({ node }) => {
      const { url } = node;
      return <Player url={url} />;
    },
    customBlockContent: ({ node }) => {
      return <CustomBlockContent node={node} />;
    },
  },
  list: List,
  listItem: ListItem,
};

export const BlockContent = ({ blocks, className }) => {
  return (
    <BaseBlockContent
      blocks={blocks.map((block, idx) => (idx === 0 ? { ...block, first: true } : block))}
      className={className}
      serializers={serializers}
      {...clientConfig.sanity}
    />
  );
};
